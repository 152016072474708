"use client";

import React, { type FC, useState } from "react";

import { isNodeSynced } from "@/api/app";

import { ViewNodeInfoTemplate } from "./ViewNodeInfoTemplate";

interface ViewNodeSyncStatusProps {
  node: string;
}

const statusStyles = {
  false: {
    label: "Not synced",
    sx: { color: "var(--mean-error)" },
  },
  true: {
    label: "Synced",
    sx: { color: "var(--mean-success)" },
  },
  null: {
    label: "Unknown",
    sx: { color: "var(--mean-warning)" },
  },
};

/**
 * Renders a component to view the sync status of a node.
 *
 * @component
 * @example
 * ```tsx
 * <ViewNodeSyncStatus node={node} />
 * ```
 */
export const ViewNodeSyncStatus: FC<ViewNodeSyncStatusProps> = (props) => {
  const [status, setStatus] = useState<boolean | null | undefined>(undefined);

  const handleOnView = async () => {
    const { node } = props;
    const value = await isNodeSynced(node);

    setStatus(value);
    return value;
  };

  const format = (value: boolean | null) => statusStyles[`${value}`].label;

  const handleOnFail = (error: unknown) => {
    console.error("Error occurred while fetching node status", { error });
    return "Error occurred";
  };

  const label = "View sync status";
  const sx = status ? statusStyles[`${status}`].sx : undefined;

  return <ViewNodeInfoTemplate label={label} onView={handleOnView} format={format} onFail={handleOnFail} sx={sx} />;
};
