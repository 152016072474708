import { configureStore } from "@reduxjs/toolkit";

import { Environment, getEnvironment } from "../util";
import { userSlice } from "./feature/user/user-slice";

export const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
  },

  devTools: getEnvironment() === Environment.Local,
});

export const makeStore = () => store;

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
