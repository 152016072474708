"use client";

import dayjs from "dayjs";
import React, { type FC, type ReactNode, useEffect, useState } from "react";

import type { NodeHeartbeat, NodeHeartbeatLog } from "@/api/app";

import { useNodeUptime } from "../../client/NodeUptimeProvider";
import { type NodeHeartbeatContext, Provider } from "./node-heartbeat-context";

interface NodeHeartbeatProviderProps {
  children: ReactNode;
}

/**
 * Retrieves the latest heartbeat log from the given heartbeat object.
 *
 * @param heartbeat - The NodeHeartbeat object to retrieve the latest heartbeat log from.
 * @returns The latest heartbeat log, or null if the heartbeat object is null or has no logs.
 */
const getLatestHeartbeatLog = (heartbeat: NodeHeartbeat | null) => {
  if (heartbeat == null || heartbeat.heartbeatLogs.length === 0) {
    return null;
  }

  return heartbeat.heartbeatLogs[heartbeat.heartbeatLogs.length - 1]!;
};

const DEFAULT_LAST_DAYS = 30;

export const NodeHeartbeatProvider: FC<NodeHeartbeatProviderProps> = (props) => {
  const { heartbeat, loading, error } = useNodeUptime();
  const [selectedHeartbeatLog, setSelectedHeartbeatLog] = useState<NodeHeartbeatLog | null>(() => getLatestHeartbeatLog(heartbeat));
  const [sinceUnixSeconds, setSinceUnixSeconds] = useState(() => dayjs().subtract(DEFAULT_LAST_DAYS, "days").unix());

  useEffect(() => {
    setSelectedHeartbeatLog(getLatestHeartbeatLog(heartbeat));
  }, [heartbeat]);

  const { children } = props;
  const ctxValue: NodeHeartbeatContext = {
    loading,
    error,
    sinceUnixSeconds,
    setSinceUnixSeconds,
    nodeHeartbeat: heartbeat,
    selectedHeartbeatLog,
    setSelectedHeartbeatLog,
  };

  return <Provider value={ctxValue}>{children}</Provider>;
};
