"use client";

import React, { type FC, useState } from "react";

import { isNodeActive } from "@/api/app";
import type { Nullish } from "@/types/utils";

import { ViewNodeInfoTemplate } from "./ViewNodeInfoTemplate";

interface ViewNodeSyncStatusProps {
  node: string;
}

const activeStyles = {
  label: "Online",
  sx: { color: "var(--mean-success)" },
};

const inactiveStyles = {
  label: "Offline",
  sx: { color: "var(--mean-error)" },
};

export const ViewNodeStatus: FC<ViewNodeSyncStatusProps> = (props) => {
  const [status, setStatus] = useState<Nullish<boolean>>(undefined);

  const handleOnView = async () => {
    const { node } = props;
    const value = await isNodeActive(node);
    setStatus(value);
    return value;
  };

  const format = (value: typeof status) => (value == null ? "Unknown" : value ? activeStyles.label : inactiveStyles.label);

  const handleOnFail = (error: unknown) => {
    console.error("Error occurred while fetching node status", { error });
    return "Error occurred";
  };

  const sx = status ? activeStyles.sx : inactiveStyles.sx;
  const label = "View status";

  return <ViewNodeInfoTemplate label={label} onView={handleOnView} format={format} onFail={handleOnFail} sx={sx} />;
};
