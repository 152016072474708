"use client";

import { Button, TableCell, Typography } from "@mui/material";
import dayjs from "dayjs";
import Link from "next/link";
import React, { type FC } from "react";

import type { Network, UserNetworkNode } from "@/api/app";
import { config } from "@/app/config";
import { useAppSelector } from "@/lib/hooks/client";
import { selectUser } from "@/lib/state";
import { isAdmin } from "@/lib/util";
import { ClipText, TableRow } from "@/ui/shared";

import { useSearch } from "../../client/SearchProvider";

interface NetworkNodesOverviewRowsProps {
  nodes: UserNetworkNode[];
  network: Network;
}

const cellStyle = { my: 1, fontSize: 12, fontWeight: 500, textWrap: "nowrap" };

export const NetworkNodesOverviewRows: FC<NetworkNodesOverviewRowsProps> = (props) => {
  const search = useSearch();
  const { nodes, network } = props;
  const user = useAppSelector(selectUser);
  const filteredNodes = search.value ? nodes.filter((node) => node.address.includes(search.value)) : nodes;
  const getViewDetailsHref = (nodeID: string) =>
    user == null || !isAdmin(user) ? `/me/network/${network.id}/node/${nodeID}` : `/node/${nodeID}/activity`;

  return filteredNodes.map((node, i) => (
    <TableRow key={node.nodeID} stripped>
      <TableCell sx={cellStyle}>{i + 1}</TableCell>
      <TableCell sx={cellStyle}>
        <ClipText text={node.address} sx={{ width: "min(200px, 30vw)" }} ellipsis title={node.address} />
      </TableCell>
      <TableCell align="right" sx={cellStyle}>
        {node.runningSince ? dayjs(node.runningSince).format(config.intl.dateFormat) : "N/A"}
      </TableCell>
      <TableCell align="right" sx={cellStyle}>
        {node.staked}
      </TableCell>
      <TableCell align="right" sx={cellStyle}>
        {node.rewards}
      </TableCell>
      <TableCell sx={cellStyle}>
        <Typography
          component="span"
          sx={{ textDecoration: "underline" }}
          textTransform="capitalize"
          fontSize="inherit"
          color={`var(--mean-${node.isActive ? "success" : "error"})`}
        >
          {node.isActive ? "Active" : "Inactive"}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={cellStyle}>
        <Button LinkComponent={Link} variant="ghost" href={getViewDetailsHref(node.nodeID)} sx={{ color: "var(--text-accent)", fontSize: 12 }}>
          View details
        </Button>
      </TableCell>
    </TableRow>
  ));
};
