import dayjs from "dayjs";

import type { NodeHeartbeat } from "@/api/app";

interface CacheNodeHeartbeatSchema {
  expireAt: number;
  heartbeat: NodeHeartbeat;
}

const KEY_PREFIX = "cache-node-heartbeat";

export class CacheNodeHeartbeat {
  private static getExpireAt() {
    const now = dayjs();

    if (now.minute() < 30) {
      return now.set("minute", 30).set("second", 0).set("millisecond", 0).unix();
    }

    return now.add(1, "hour").set("minute", 0).set("second", 0).set("millisecond", 0).unix();
  }

  private static isExpired(schema: CacheNodeHeartbeatSchema): boolean {
    return dayjs().unix() > schema.expireAt;
  }

  private static getKey(nodeID: string) {
    return `${KEY_PREFIX}-${nodeID}`;
  }

  public static setCacheNodeHeartbeat(nodeID: string, heartbeat: NodeHeartbeat) {
    const data: CacheNodeHeartbeatSchema = {
      expireAt: this.getExpireAt(),
      heartbeat,
    };
    localStorage.setItem(this.getKey(nodeID), JSON.stringify(data));
  }

  public static getCacheNodeHeartbeat(nodeID: string): NodeHeartbeat | null {
    const data = localStorage.getItem(this.getKey(nodeID));

    if (data == null) {
      return null;
    }

    const schema = JSON.parse(data) as CacheNodeHeartbeatSchema;

    if (this.isExpired(schema)) {
      localStorage.removeItem(this.getKey(nodeID));
      return null;
    }

    return schema.heartbeat;
  }

  public static prune() {
    const keys = Object.keys(localStorage);

    for (const key of keys) {
      if (!key.startsWith(KEY_PREFIX)) {
        continue;
      }

      const data = localStorage.getItem(key);

      if (data == null) {
        continue;
      }

      const schema = JSON.parse(data) as CacheNodeHeartbeatSchema;

      if (this.isExpired(schema)) {
        localStorage.removeItem(key);
      }
    }
  }
}
