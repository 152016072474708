"use client";

import type { FC } from "react";

import { getNodeReward } from "@/api/app";

import { ViewNodeInfoTemplate } from "./ViewNodeInfoTemplate";

interface ViewNodeRewardsProps {
  node: string;
}

export const ViewNodeRewards: FC<ViewNodeRewardsProps> = (props) => {
  const label = "View rewards";

  const handleOnView = async () => {
    const { node } = props;
    return getNodeReward(node);
  };

  const format = (value: string | null) => value ?? "Not available";

  const handleOnFail = (error: unknown) => {
    console.error("Error occurred while fetching node status", { error });
    return "Error occurred";
  };

  return <ViewNodeInfoTemplate label={label} format={format} onView={handleOnView} onFail={handleOnFail} />;
};
