import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

const selectState = (state: RootState) => state;

/**
 * Selects the `whoAmI` property from the user state.
 *
 * @param state - The application state.
 * @returns The `whoAmI` property from the user state.
 */
export const selectUser = createSelector(selectState, (state) => state.user.whoAmI);
