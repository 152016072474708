import { ButtonBase, type ButtonBaseProps } from "@mui/material";
import type { FC, ReactNode } from "react";

interface TabProps extends ButtonBaseProps {
  children: string | ReactNode;

  selected?: boolean;
}

export const Tab: FC<TabProps> = (props) => {
  const { selected, sx, children, ...buttonProps } = props;

  return (
    <ButtonBase
      sx={{
        px: 2,
        py: "2px",
        fontSize: "9px",
        fontWeight: 500,
        lineHeight: "16px",
        bgcolor: selected ? "var(--primary-color)" : "transparent",
        color: selected ? "white" : "var(--text-accent)",
        textWrap: "nowrap",
        ...sx,
      }}
      {...buttonProps}
    >
      {children}
    </ButtonBase>
  );
};
