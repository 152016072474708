"use client";

import dayjs from "dayjs";
import { type FC, type ReactNode, useEffect, useState } from "react";

import { getNodeHeartbeat, type NetworkNode, type NodeHeartbeat } from "@/api/app";

import { type NodeHeartbeatContext, Provider } from "./node-heartbeat-context";
import { CacheNodeHeartbeat } from "./utils";

interface NodeUptimeProviderProps {
  node: NetworkNode["nodeID"];

  children: ReactNode;

  initial?: Partial<NodeHeartbeatContext>;
}

export const NodeHeartBeatProvider: FC<NodeUptimeProviderProps> = (props) => {
  const { node, children } = props;
  const [loading, setLoading] = useState(props.initial?.loading ?? false);
  const [nodeHeartbeat, setNodeHeartbeat] = useState<NodeHeartbeat | null>(props.initial?.heartbeat ?? null);
  const [error, setError] = useState<Error | null>(props.initial?.error ?? null);
  const isDataLoaded = nodeHeartbeat != null;

  useEffect(() => {
    const initNodeUptime = async () => {
      try {
        setError(null);
        setLoading(true);

        let heartbeat = CacheNodeHeartbeat.getCacheNodeHeartbeat(node);

        if (heartbeat == null) {
          const last90Days = dayjs().subtract(90, "days").set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0).unix();
          heartbeat = await getNodeHeartbeat(node, { since: last90Days });
        }

        if (heartbeat == null) {
          throw new Error("Failed to fetch node uptime");
        }

        setNodeHeartbeat(heartbeat);
        CacheNodeHeartbeat.setCacheNodeHeartbeat(node, heartbeat);
      } catch (err) {
        setError(new Error("Failed to fetch node uptime", { cause: err }));
        setNodeHeartbeat(null);
      } finally {
        setLoading(false);
      }
    };

    void initNodeUptime();
  }, [node, isDataLoaded]);

  useEffect(() => {
    CacheNodeHeartbeat.prune();
  }, []);

  return <Provider value={{ loading, error, heartbeat: nodeHeartbeat }}>{children}</Provider>;
};
