import { useContext } from "react";

import { type NodeHeartbeatContext, nodeUptimeContext } from "./node-heartbeat-context";

export const useNodeUptime = (): NodeHeartbeatContext => {
  const context = useContext(nodeUptimeContext);

  if (context == null) {
    throw new Error("`useNodeUptime` must be wrapped with NodeUptimeProvider");
  }

  return context;
};
