import { Box } from "@mui/material";
import React, { type FC, type ReactNode } from "react";

interface TabsProps {
  children: ReactNode;
}

export const Tabs: FC<TabsProps> = (props) => {
  const { children } = props;
  return (
    <Box
      display="flex"
      sx={{
        border: 1,
        overflow: "hidden",
        borderColor: "var(--primary-color)",
        borderRadius: 50,
        "& > *": {
          flex: 1,
          ":not(:last-child)": {
            borderRight: 1,
            borderColor: "var(--primary-color)",
          },
        },
      }}
    >
      {children}
    </Box>
  );
};
