"use client";

import { Box, Skeleton, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext } from "react";

import { context } from "./node-heartbeat-context";

export const NodeHeartbeatUptimeRatio = () => {
  const { loading, selectedHeartbeatLog, error } = useContext(context);

  return loading ? (
    <>
      <Skeleton variant="text" width={40} height={22.5} sx={{ lineHeight: 18 }} />
      <Skeleton variant="text" width={60} height={18.5} />
    </>
  ) : error ? (
    <div>{error.message}</div>
  ) : (
    <Box>
      <Typography fontWeight={500} fontSize={15} color={"var(--text-hi)"}>
        {selectedHeartbeatLog == null ? "N/A" : `${(selectedHeartbeatLog.stat.totalUptime * 100).toFixed(2)}%`}
      </Typography>

      {selectedHeartbeatLog != null && (
        <Typography fontSize={12} color={"var(--text-high-emphasis)"}>
          Date: {dayjs.unix(selectedHeartbeatLog.timestamp).format("MMMM DD")}
        </Typography>
      )}
    </Box>
  );
};
