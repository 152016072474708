"use client";

import { Skeleton, Typography } from "@mui/material";
import React from "react";

import { ClipText } from "@/ui/shared";

import { useNodeUptime } from "../../client/NodeUptimeProvider";

export const ViewNodeUptime = () => {
  const { loading, error, heartbeat } = useNodeUptime();
  const fontSize = 12;
  const fontWeight = 600;

  return loading ? (
    <Skeleton variant="text" height={20} width={60} />
  ) : error != null ? (
    <ClipText text={"Error occurred"} ellipsis sx={{ color: "var(--mean-error)", fontSize, fontWeight }} />
  ) : heartbeat == null ? null : (
    <Typography sx={{ fontSize, fontWeight }}>{`${(heartbeat.stat.totalUptime * 100).toFixed(2)}%`}</Typography>
  );
};
