"use client";

import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import numWords from "num-words";

import { cipherNodeLogoBase64, invoiceSignatureBase64 } from "@/ui/shared/icons";

const borderColor = "#999";
const grayBackground = "#dddddd";

interface ReceiptContentProps {
  date: Date;
  amount: number;
  userName: string;
  nodeCount: number;
}
export const ReceiptContent = (props: ReceiptContentProps) => {
  const { date, amount, userName, nodeCount } = props;
  const formattedDate = dayjs(date).format("DD/MM/YYYY");

  const commonBorder = `1px solid ${borderColor}`;

  const totalInRuppess = amount;

  const subTotal = (totalInRuppess * 0.82).toFixed(2);
  const cgst = (totalInRuppess * 0.09).toFixed(2);

  const sgst = (totalInRuppess * 0.09).toFixed(2);
  const total = totalInRuppess.toFixed(2);
  // const totalAmountInWords = numWords(totalInRuppess || 0);
  // const totalAmountInWords = numWords(Math.floor(totalInRuppess)) + " Rupees " + (totalInRuppess - Math.floor(totalInRuppess)) * 100 + " Paise";
  const totalAmountInWords = numWords(Math.floor(totalInRuppess));

  Font.register({
    family: "NotoSans",
    src: "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9A99d.ttf",
    fontWeight: 400,
  });

  Font.register({
    family: "NotoSans",
    src: "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyAaBN9d.ttf",
    fontWeight: 700,
  });

  Font.register({
    family: "NotoSans",
    src: "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QNAZ6Vc.ttf",
    fontWeight: 700,
    fontStyle: "italic",
  });

  return (
    <Document>
      <Page size="A4" style={{ paddingHorizontal: 20, paddingVertical: 24, fontFamily: "NotoSans" }}>
        <View style={{ border: commonBorder, borderRadius: 2 }}>
          <View style={{ display: "flex", paddingVertical: 6, flexDirection: "row", alignItems: "center", borderBottom: commonBorder }}>
            {/*  eslint-disable-next-line jsx-a11y/alt-text */}
            <Image src={cipherNodeLogoBase64} style={{ flexBasis: "20%", paddingHorizontal: 12 }} />

            <View style={{ flexBasis: "40%" }}>
              <Text style={{ fontSize: 14, fontWeight: 700 }}>Ciphernode Private Limited</Text>
              <Text style={{ fontSize: 11, marginTop: 4 }}>Tamil Nadu</Text>
              <Text style={{ fontSize: 11 }}>India</Text>
              <Text style={{ fontSize: 11 }}>GSTIN 33AAECF4297K1ZC</Text>
              <Text style={{ fontSize: 11 }}>accounts@ciphernode.ai</Text>
            </View>
            <View
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                paddingRight: 10,
              }}
            >
              <Text style={{ fontSize: 26, fontWeight: 700 }}>TAX INVOICE</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", borderBottom: commonBorder }}>
            <View style={{ flexGrow: 1, borderRight: commonBorder, padding: 6, display: "flex", flexDirection: "row" }}>
              <View style={{ flexGrow: 1 }}>
                <Text style={{ fontSize: 10 }}>#</Text>
                <Text style={{ fontSize: 10 }}>Invoice Date</Text>
                <Text style={{ fontSize: 10 }}>Terms</Text>
                <Text style={{ fontSize: 10 }}>Due Date</Text>
              </View>
              <View style={{ flexGrow: 1 }}>
                <Text style={{ fontSize: 10, fontWeight: 700 }}>: GST2024-25/00001</Text>
                <Text style={{ fontSize: 10, fontWeight: 700 }}>: {formattedDate}</Text>
                <Text style={{ fontSize: 10, fontWeight: 700 }}>: Due on Receipt</Text>
                <Text style={{ fontSize: 10, fontWeight: 700 }}>: {formattedDate}</Text>
              </View>
            </View>
            <View style={{ flexBasis: "50%", padding: 6, display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <Text style={{ fontSize: 10, flexBasis: "50%" }}>Place Of Supply</Text>
              <Text style={{ fontSize: 10, fontWeight: 700 }}>: Tamil Nadu (33)</Text>
            </View>
          </View>
          <View style={{ borderBottom: commonBorder }}>
            <Text style={{ fontSize: 11, paddingLeft: 6, paddingVertical: 3, backgroundColor: grayBackground, fontWeight: 700 }}>Bill To</Text>
          </View>

          <View style={{ borderBottom: commonBorder }}>
            <Text style={{ fontSize: 14, paddingLeft: 6, paddingVertical: 8, fontWeight: 700 }}>{userName}</Text>
          </View>
          <View style={{ borderBottom: commonBorder, fontWeight: 700 }}>
            <View style={{ display: "flex", flexDirection: "row", backgroundColor: grayBackground, fontSize: 11 }}>
              <View style={{ borderRight: commonBorder, flexBasis: "5%" }}>
                <Text style={{ textAlign: "center" }}>#</Text>
              </View>
              <View style={{ flexGrow: 1, borderRight: commonBorder }}>
                <Text>Item & Description</Text>
              </View>
              <View style={{ flexBasis: "10%", borderRight: commonBorder }}>
                <Text>HSN/SAC</Text>
              </View>
              <View style={{ flexBasis: "10%", borderRight: commonBorder }}>
                <Text>Qty</Text>
              </View>
              <View style={{ flexBasis: "18%", borderRight: commonBorder }}>
                <Text style={{ textAlign: "center", borderBottom: commonBorder }}>CGST</Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text style={{ flexBasis: "50%", textAlign: "center", borderRight: commonBorder }}>%</Text>
                  <Text style={{ flexBasis: "50%", textAlign: "center" }}>Amt</Text>
                </View>
              </View>
              <View style={{ flexBasis: "18%", borderRight: commonBorder }}>
                <Text style={{ fontSize: 11, textAlign: "center", borderBottom: commonBorder }}>SGST</Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text style={{ flexBasis: "50%", textAlign: "center", borderRight: commonBorder }}>%</Text>
                  <Text style={{ flexBasis: "50%", textAlign: "center" }}>Amt</Text>
                </View>
              </View>
              <View style={{ flexBasis: "10%" }}>
                <Text style={{ fontSize: 11 }}>Amount</Text>
              </View>
            </View>
          </View>

          <View style={{ borderBottom: commonBorder }}>
            <View style={{ display: "flex", flexDirection: "row", fontSize: 11 }}>
              <View style={{ borderRight: commonBorder, flexBasis: "5%" }}>
                <Text style={{ textAlign: "center" }}>1</Text>
              </View>
              <View style={{ flexGrow: 1, borderRight: commonBorder }}>
                <Text>Validator Node Management</Text>
              </View>
              <View style={{ flexBasis: "10%", borderRight: commonBorder }}>
                <Text>998313</Text>
              </View>
              <View style={{ flexBasis: "10%", borderRight: commonBorder }}>
                <Text>{nodeCount}</Text>
              </View>
              <View style={{ flexBasis: "18%", borderRight: commonBorder }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text style={{ flexBasis: "50%", textAlign: "center", borderRight: commonBorder }}>9%</Text>
                  <Text style={{ flexBasis: "50%", textAlign: "right" }}>{cgst}</Text>
                </View>
              </View>
              <View style={{ flexBasis: "18%", borderRight: commonBorder }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text style={{ flexBasis: "50%", textAlign: "center", borderRight: commonBorder }}>9%</Text>
                  <Text style={{ flexBasis: "50%", textAlign: "right" }}>{sgst}</Text>
                </View>
              </View>
              <View style={{ flexBasis: "10%" }}>
                <Text style={{ fontSize: 11, textAlign: "right" }}>{subTotal}</Text>
              </View>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row", fontSize: 10, textAlign: "right" }}>
            <View style={{ flexBasis: "50%", textAlign: "left", padding: 6, paddingRight: 0 }}>
              <Text>Total in Words</Text>
              <Text style={{ textTransform: "capitalize", fontWeight: 700, fontStyle: "italic" }}>{totalAmountInWords} only</Text>
              <Text style={{ marginTop: 8 }}>Notes</Text>
              <Text style={{ marginTop: 8 }}>Thanks for your business</Text>
              <Text style={{ marginTop: 8 }}>Name: FROLEARN PRIVATE LIMITED</Text>
              <Text style={{}}>A/C No: 777705620000</Text>
              <Text style={{ marginTop: 2 }}>Address: PLOT NO 6B, GOVINDASAMY NAGAR, 3RD STREET, MADIPAKKAM,CHENNAI,600091,TAMIL NADU,INDIA</Text>
            </View>
            <View style={{ flexGrow: 1, borderLeft: commonBorder, borderBottom: "none" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: commonBorder,
                }}
              >
                <View style={{ flexGrow: 1, display: "flex", alignItems: "flex-end" }}>
                  <Text>Sub Total</Text>
                  <Text>CGST9 (9%)</Text>
                  <Text>SGST9 (9%)</Text>
                  <Text style={{ fontWeight: 700 }}>Total</Text>
                  <Text style={{ fontWeight: 700 }}>Balance Due</Text>
                </View>
                <View style={{ flexGrow: 1, display: "flex", alignItems: "flex-end" }}>
                  <Text>{subTotal}</Text>
                  <Text>{cgst}</Text>
                  <Text>{sgst}</Text>
                  <Text style={{ fontWeight: 700 }}>₹{total}</Text>
                  <Text style={{ fontWeight: 700 }}>₹{total}</Text>
                </View>
              </View>
              <View style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                {/*  eslint-disable-next-line jsx-a11y/alt-text */}
                <Image src={invoiceSignatureBase64} style={{ flexBasis: "8%" }} />

                <Text style={{ textAlign: "center", marginBottom: -100 }}>Authorized Signature</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
