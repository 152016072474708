"use client";

import { useContext } from "react";

import { context } from "./search-context";

/**
 * Custom hook that returns the search context.
 *
 * @returns The search context.
 */
export const useSearch = () => useContext(context);
