"use client";

import { type FC, type ReactNode, useState } from "react";

import { context, type SearchProviderContext } from "./search-context";

interface SearchProviderProps extends Partial<Omit<SearchProviderContext, "fields">> {
  children: ReactNode;
  fields: string[];
}

export const SearchProvider: FC<SearchProviderProps> = (props) => {
  const { fields, children } = props;
  const [value, setValue] = useState(props.value ?? "");

  return <context.Provider value={{ fields, value, setValue: (newValue: string) => setValue(() => newValue) }}>{children}</context.Provider>;
};
