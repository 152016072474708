"use client";

import { Button } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useState } from "react";

import type { SubscriptionPopulated } from "@/api/app/payment/types";
import { DownloadIcon } from "@/ui/shared/icons";

import { ReceiptContent } from "./ReceiptContent";

export const ReceiptDownload = (props: {
  transactionData: SubscriptionPopulated["transactionData"][0];
  subscriptionDetails: SubscriptionPopulated;
  userName: string;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!isLoaded) return null;

  return (
    <PDFDownloadLink
      document={
        <ReceiptContent
          nodeCount={props.subscriptionDetails.nodeDetails[0].nodeCount}
          userName={props.userName}
          amount={props.transactionData.amount / 100}
          date={new Date(props.transactionData.date)}
        />
      }
      fileName={`${props.transactionData.providerReferenceId}.pdf`}
    >
      <Button size="small">
        Download Invoice&nbsp;&nbsp;
        <DownloadIcon />
      </Button>
    </PDFDownloadLink>
  );
};
