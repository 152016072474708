"use client";

import { Box } from "@mui/material";
import dynamic from "next/dynamic";
import { useContext, useMemo } from "react";

import { getDaysTillNow } from "@/lib/util";

import { context } from "./node-heartbeat-context";

const ChartLoading = (props: { message: string }) => (
  <Box height={228} display="flex" sx={{ alignItems: "center", justifyContent: "center" }}>
    <span>{props.message}</span>
  </Box>
);

const LazyLoadedNodeHeartbeatChart = dynamic(() => import("./NodeHeartbeatViz").then((mod) => mod.NodeHeartbeatChartViz), {
  ssr: false,
  loading: () => <ChartLoading message="Crafting a perfect chart for you" />,
});

export const NodeHeartbeatChart = () => {
  const { error, loading, nodeHeartbeat, setSelectedHeartbeatLog, sinceUnixSeconds } = useContext(context);

  const lastNDaysData = useMemo(() => {
    const data = nodeHeartbeat?.heartbeatLogs ?? [];
    return data.slice(-getDaysTillNow(sinceUnixSeconds));
  }, [nodeHeartbeat, sinceUnixSeconds]);

  return (
    <Box height={228}>
      {loading ? (
        <ChartLoading message="Downloading your node data.." />
      ) : error ? (
        "Error"
      ) : (
        <LazyLoadedNodeHeartbeatChart data={lastNDaysData} onBarHover={setSelectedHeartbeatLog} />
      )}
    </Box>
  );
};
