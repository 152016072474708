import { createContext } from "react";

import type { NodeHeartbeat, NodeHeartbeatLog } from "@/api/app";
import type { UnixSeconds } from "@/types/utils";

export interface NodeHeartbeatContext {
  loading: boolean;
  error: Error | null;
  nodeHeartbeat: NodeHeartbeat | null;
  selectedHeartbeatLog: NodeHeartbeatLog | null;
  setSelectedHeartbeatLog: (day: NodeHeartbeatLog) => void;
  sinceUnixSeconds: UnixSeconds;
  setSinceUnixSeconds: (since: UnixSeconds) => void;
}

const nullFunction = () => {};

export const context = createContext<NodeHeartbeatContext>({
  loading: true,
  error: null,
  nodeHeartbeat: null,
  selectedHeartbeatLog: null,
  setSelectedHeartbeatLog: nullFunction,
  setSinceUnixSeconds: nullFunction,
  sinceUnixSeconds: 0,
});

export const { Provider } = context;
