"use client";

import { createContext } from "react";

export interface SearchProviderContext {
  fields: string[];
  value: string;
  setValue: (value: string) => void;
}

export const context = createContext<SearchProviderContext>({
  fields: [],
  value: "",
  setValue: () => {},
});
