"use client";

import { FormControl, InputBase, type InputBaseProps } from "@mui/material";
import React, { type ChangeEventHandler, type FC } from "react";

import { useSearch } from "./useSearch";

type SearchInputFieldProps = InputBaseProps;

export const SearchInputField: FC<SearchInputFieldProps> = (props) => {
  const { value, setValue } = useSearch();
  const { onChange: clientOnChange, ...inputProps } = props;

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    clientOnChange?.(event);
    setValue(event.target.value);
  };

  return (
    <FormControl>
      <InputBase size="small" placeholder="Search by email" value={value} {...inputProps} onChange={onChange} />
    </FormControl>
  );
};
