"use client";

import type { FC } from "react";

import { getNodeGeolocation } from "@/api/app";

import { ViewNodeInfoTemplate } from "./ViewNodeInfoTemplate";

interface ViewNodeGeolocationProps {
  node: string;
}

export const ViewNodeGeolocation: FC<ViewNodeGeolocationProps> = (props) => {
  const label = "View location";

  const handleOnView = async () => {
    const { node } = props;
    const location = await getNodeGeolocation(node);
    if (location == null) {
      return "Not available";
    }

    return `${location.city}, ${location.country}`;
  };

  const format = (value: string | null) => value ?? "Unknown";

  const handleOnFail = (error: unknown) => {
    console.error("Error occurred while fetching node status", { error });
    return "Error occurred";
  };

  return <ViewNodeInfoTemplate label={label} format={format} onView={handleOnView} onFail={handleOnFail} />;
};
