"use client";

import dayjs from "dayjs";
import { useContext, useState } from "react";

import { Tab } from "@/ui/shared/Tab";
import { Tabs } from "@/ui/shared/Tabs";

import { context } from "./node-heartbeat-context";

const dayRanges = [30, 60, 90];

export const NodeHeartBeatRangeSelector = () => {
  const { setSinceUnixSeconds } = useContext(context);
  const [value, setValue] = useState(dayRanges[0]);

  const handleTabChange = (newValue: number) => {
    setSinceUnixSeconds(dayjs().unix() - dayjs.duration(newValue, "days").asSeconds());
    return setValue(newValue);
  };

  return (
    <Tabs>
      {dayRanges.map((range) => (
        <Tab key={range} onClick={() => handleTabChange(range)} selected={value === range}>
          {range} days
        </Tab>
      ))}
    </Tabs>
  );
};
