"use client";

import { createContext } from "react";

import type { NodeHeartbeat } from "@/api/app";

export interface NodeHeartbeatContext {
  loading: boolean;
  error: Error | null;
  heartbeat: NodeHeartbeat | null;
}

export const nodeUptimeContext = createContext<NodeHeartbeatContext | null>(null);

export const { Provider } = nodeUptimeContext;
