import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { User } from "@/api/app";

export interface UserStore {
  whoAmI: User | null;
}

const NAME = "user";

const initialState: UserStore = {
  whoAmI: null,
};

export const userSlice = createSlice({
  name: NAME,

  initialState,

  reducers: {
    /**
     * Sets the current user.
     *
     * @param state - The current state.
     * @param action - The action to perform.
     * @returns The new state.
     */
    setUser: (state, action: PayloadAction<User | null>) => ({ ...state, whoAmI: action.payload }),
  },
});

export const { setUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
