"use client";

import type { Theme } from "@emotion/react";
import { Box, Button, Skeleton, SvgIcon, type SxProps, Typography } from "@mui/material";
import React, { type ReactNode, useState } from "react";

import type { Func, Nullish } from "@/types/utils";
import { ClipText } from "@/ui/shared";
import { RefreshIcon } from "@/ui/shared/icons";

interface ViewNodeSyncStatusProps<TValue> {
  label: string;
  onView: () => (TValue | null) | Promise<TValue | null>;
  value?: TValue;
  format: Func<[value: TValue | null], ReactNode>;
  onFail?: (error: unknown) => string;
  onError?: (error: unknown) => void;

  sx?: SxProps<Theme>;
}

/**
 * Renders the view node info template.
 *
 * @template T - The type of the value.
 * @param {ViewNodeSyncStatusProps<T>} props - The props for the component.
 * @returns - The rendered component.
 */
export function ViewNodeInfoTemplate<T>(props: ViewNodeSyncStatusProps<T>) {
  const [value, setValue] = useState<Nullish<T>>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fontSize = 12;
  const fontWeight = 600;

  const handleViewStatus = async () => {
    try {
      const { onView } = props;

      setValue(null);
      setError(null);
      setLoading(true);
      setValue(await onView());
    } catch (err) {
      const { onError, onFail } = props;

      onError?.(err);

      if (onFail) {
        setError(new Error(onFail(err), { cause: err }));
      }
    } finally {
      setLoading(false);
    }
  };

  const { label, format, sx } = props;
  return loading ? (
    <Skeleton variant="text" height={20} width={60} />
  ) : error ? (
    <Box display="flex">
      <Box>
        <ClipText text={error.message} ellipsis sx={{ color: "var(--mean-error)", fontSize, fontWeight }} />
      </Box>
      <Button variant="ghost" onClick={() => void handleViewStatus()}>
        <SvgIcon sx={{ color: "var(--mean-error)" }}>
          <RefreshIcon />
        </SvgIcon>
      </Button>
    </Box>
  ) : value !== undefined ? (
    <Box display="inline-flex" alignItems="center" sx={sx}>
      <Typography component="span" sx={{ fontSize, fontWeight, ...sx }}>
        {format(value)}
      </Typography>
      <SvgIcon
        component={Button}
        variant="ghost"
        onClick={() => void handleViewStatus()}
        sx={{ display: "inline-flex", alignItems: "center", color: "inherit" }}
      >
        <RefreshIcon />
      </SvgIcon>
    </Box>
  ) : (
    <Button variant="ghost" sx={{ color: "var(--text-accent)", fontSize, fontWeight }} onClick={() => void handleViewStatus()}>
      {label}
    </Button>
  );
}
