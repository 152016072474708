"use client";

import { type FC, type ReactNode, useRef } from "react";
import { Provider } from "react-redux";

import { type User } from "@/api/app";

import { setUser } from "./feature/user/user-slice";
import { type AppStore, makeStore } from "./store";

interface IProps {
  children: ReactNode;

  user?: User | null;
}

/**
 * Provides the store to the application components.
 *
 * @component
 * @param props - The component props.
 * @param props.children - The child components.
 * @returns The rendered component.
 */
export const StoreProvider: FC<IProps> = (props) => {
  const { user, children } = props;
  const storeRef = useRef<AppStore>();

  if (storeRef.current == null) {
    storeRef.current = makeStore();

    if (user != null) {
      storeRef.current.dispatch(setUser(user));
    }
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
};
