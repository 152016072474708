"use client";

import { Box, Skeleton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import React, { useContext, useMemo } from "react";

import type { NodeHeartbeatLog } from "@/api/app";
import { getFormattedDuration } from "@/lib/util";
import type { UnixSeconds } from "@/types/utils";

import { context } from "./node-heartbeat-context";

dayjs.extend(durationPlugin);

interface HeartbeatStats {
  uptimeDuration: UnixSeconds;
  downtimeDuration: UnixSeconds;
}

/**
 * Calculates the uptime and downtime durations based on the given NodeHeartbeatLog record.
 *
 * @param record - The NodeHeartbeatLog record containing the statistics.
 * @returns An object containing the calculated uptime and downtime durations.
 */
const getHeartbeatStats = (record: NodeHeartbeatLog): HeartbeatStats => {
  const secondsInDay = dayjs.duration(1, "day").asSeconds();
  const uptimeDuration = record.stat.totalUptime * secondsInDay;
  const downtimeDuration = record.stat.totalDowntime * secondsInDay;

  return { uptimeDuration, downtimeDuration };
};

export const NodeHeartbeatLegend = () => {
  const { selectedHeartbeatLog, loading, error } = useContext(context);
  const stats = useMemo(() => (selectedHeartbeatLog == null ? null : getHeartbeatStats(selectedHeartbeatLog)), [selectedHeartbeatLog]);

  return (
    <Stack mt={0} direction="row" spacing={1} width={"100%"}>
      <Box sx={{ fontSize: 9, fontWeight: 500, minWidth: 100 }}>
        <Typography
          component="span"
          fontSize="inherit"
          fontWeight="inherit"
          sx={{
            position: "relative",

            "&::before": {
              content: "''",
              transform: "translate(0, 20%)",
              mr: 0.5,
              display: "inline-flex",
              width: 10,
              height: 10,
              bgcolor: "var(--graph-color-5)",
              borderRadius: "50%",
            },
          }}
        >
          Uptime:
        </Typography>
        {loading ? (
          <Skeleton variant="text" sx={{ display: "inline-block", ml: 0.5 }} width={30} height={12.5} />
        ) : error ?? stats == null ? null : (
          <Typography component="span" fontSize="inherit" fontWeight="inherit" color={"var(--text-high-emphasis)"} display="inline-block" ml={0.5}>
            {getFormattedDuration(stats.uptimeDuration)}
          </Typography>
        )}
      </Box>

      <Box sx={{ fontSize: 9, fontWeight: 500, display: "flex", alignItems: "center" }}>
        <Typography
          component="span"
          fontSize="inherit"
          fontWeight="inherit"
          sx={{
            position: "relative",

            "&::before": {
              content: "''",
              transform: "translate(0, 20%)",
              mr: 0.5,
              display: "inline-flex",
              width: 10,
              height: 10,
              bgcolor: "var(--graph-color-2)",
              borderRadius: "50%",
            },
          }}
        >
          Downtime:
        </Typography>
        {loading ? (
          <Skeleton variant="text" sx={{ display: "inline-block", ml: 0.5 }} width={30} height={12.5} />
        ) : error ?? stats == null ? null : (
          <Typography component="span" fontSize="inherit" fontWeight="inherit" color={"var(--text-high-emphasis)"} display="inline-block" ml={0.5}>
            {getFormattedDuration(stats.downtimeDuration)}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
